import styles from "./VulnerabilityCard.module.scss";

const VulnerabilityCard = (props) => {
    return (
        <div 
            className={`${styles.vulCard} ${props.active ? styles.active : ''}`} 
            onClick={() => {
                props.handleClick(props);
            }}
        >
            <div className={styles.library}>
                <h3>{props.libraryName}</h3>
            </div>
            <div className={styles.version}>
                <h3>{props.version}</h3>
            </div>
            <div className={styles.severity}>
                <h3 className={props.severity.toLowerCase()}>{props.severity}</h3>
            </div>
            {
                props.showDescription 
                ? <div className={styles.description}>
                    <p>{props.description}</p>
                </div>
                : ""
            }
        </div>
    );
}

export default VulnerabilityCard;